import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'

// GERAL
export const getRulesAuthUser = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.myRules(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getProfiles = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.getPerfis(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getDepartments = () => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.departamento())
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getRegions = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.regiao(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getNationalBaseActivityTypes = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.baseNacionalTiposAtuacao(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// SESI
export const getAdminSesiUsers = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioAdmin(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getAdminSesiId = id => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioAdminId(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const createAdminSesiUser = form => new Promise((resolve, reject) => {
  axiosIns
    .post(rotasAPI.usuarioAdmin(), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateAdminSesiUser = (id, form) => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.usuarioAdminId(id), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateStatusAdminSesiUser = id => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.usuarioAdminAtivarInativar(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// EMPRESA
export const getCompanyUsers = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioEmpresa(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCompanyUsersNoCompanies = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioEmpresaSemVinculo(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCompanyUserId = id => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioEmpresaId(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCompanyUserNoCompany = id => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.usuarioEmpresaIdSemVinculo(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const createCompanyUser = form => new Promise((resolve, reject) => {
  axiosIns
    .post(rotasAPI.usuarioEmpresa(), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateCompanyUser = (id, form) => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.usuarioEmpresaId(id), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateStatusCompanyUser = id => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.usuarioEmpresaAtivarInativar(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getLinkedUserCompanies = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.empresasVinculadasUsuario(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getGroups = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.getAndPostGrupo(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCompaniesFromGroup = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.saveAndGetEmpresa(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})