export const subjects = {
  DASHBOARD_EMPRESA: 'DASHBOARD_EMPRESA',
  DASHBOARD_DN: 'DASHBOARD_DN',
  DASHBOARD_DR: 'DASHBOARD_DR',
  DASHBOARD_UO: 'DASHBOARD_UO',
  DASHBOARD_VACINADOR: 'DASHBOARD_VACINADOR',
  HOME_MENUS_ROTAS: 'HOME_MENUS_ROTAS',
  GERAL: 'GERAL',

  CAMPANHAS_MENUS_ROTAS: 'CAMPANHAS_MENUS_ROTAS',
  CAMPANHAS: 'CAMPANHAS',
  CAMPANHAS_SESI_DN: 'CAMPANHAS_SESI_DN',
  CAMPANHAS_SESI_DR: 'CAMPANHAS_SESI_DR',
  CAMPANHAS_SESI_UO: 'CAMPANHAS_SESI_UO',
  CRONOGRAMA_CAMPANHA: 'CRONOGRAMA_CAMPANHA',

  CAMPANHA_VACINA_EMPRESA: 'CAMPANHA_VACINA_EMPRESA',

  USUARIO: 'USUARIO',
  USUARIO_LISTA: 'USUARIO_LISTA',
  USUARIO_EMPRESA: 'USUARIO-EMPRESA',
  USUARIO_SESI_MENUS_ROTAS: 'USUARIO-SESI_MENUS_ROTAS',
  USUARIO_SESI_DN: 'USUARIO_SESI_DN',

  ADESAO_MENUS_ROTAS: 'ADESAO_MENUS_ROTAS',
  ADESAO_SESI_DR: 'ADESAO_SESI_DR',
  ADESAO_SESI_UO: 'ADESAO_SESI_UO',
  ADESAO_EMPRESA: 'ADESAO_EMPRESA',
  ADESAO_SESI: 'ADESAO_SESI',

  EMPRESAS_MENUS_ROTAS: 'EMPRESAS_MENUS_ROTAS',
  EMPRESAS: 'EMPRESAS',
  EMPRESAS_DADOS_MINIMOS: 'EMPRESAS_DADOS_MINIMOS',
  EMPRESA_COLABORADOR: 'EMPRESA-COLABORADOR',
  EMPRESAS_AE: 'EMPRESAS_AE',

  COLABORADOR_MENUS_ROTAS: 'COLABORADOR_MENUS_ROTAS',

  UNIDADE_SESI_MENUS_ROTAS: 'UNIDADE-SESI_MENUS_ROTAS',
  UNIDADE_UO: 'UNIDADE_UO',
  UNIDADE_DR: 'UNIDADE_DR',
  UNIDADE_DN: 'UNIDADE_DN',
  UNIDADE: 'UNIDADE',

  DEPARTAMENTO_SESI_MENUS_ROTAS: 'DEPARTAMENTO-SESI_MENUS_ROTAS',
  DEPARTAMENTO_SESI_REGIAO_MENUS_ROTAS: 'DEPARTAMENTO-SESI-REGIAO_MENUS_ROTAS',
  DEPARTAMENTO_DN: 'DEPARTAMENTO_DN',
  DEPARTAMENTO: 'DEPARTAMENTO',

  REGIAO: 'REGIAO',

  VACINA_MENUS_ROTAS: 'VACINA_MENUS_ROTAS',
  VACINA: 'VACINA',

  DOENCA_MENUS_ROTAS: 'DOENCA_MENUS_ROTAS',
  DOENCA: 'DOENCA',

  PONTO_ATENDIMENTO_SESI_MENUS_ROTAS: 'PONTO-ATENDIMENTO-SESI_MENUS_ROTAS',
  PONTO_ATENDIMENTO_UO: 'PONTO_ATENDIMENTO_UO',
  PONTO_ATENDIMENTO_DR: 'PONTO_ATENDIMENTO_DR',
  PONTO_ATENDIMENTO: 'PONTO_ATENDIMENTO',

  REGISTRO_VACINACAO_SESI_MENUS_ROTAS: 'REGISTRO_VACINACAO-SESI_MENUS_ROTAS',
  REGISTRO_VACINACAO_SESI: 'REGISTRO_VACINACAO-SESI',

  AVISOS_SESI_MENUS_ROTAS: 'AVISOS-SESI_MENUS_ROTAS',
  NOTIFICACAO_DN: 'NOTIFICACAO_DN',
  NOTIFICACAO_DR: 'NOTIFICACAO_DR',

  AJUDA_SUPORTE_MENUS_ROTAS: 'AJUDA-SUPORTE_MENUS_ROTAS',
  AJUDA_DR: 'AJUDA_DR',
  AJUDA_DN: 'AJUDA_DN',

  ESTOQUE_FISICO_MENUS_ROTAS: 'ESTOQUE_FISICO_MENUS_ROTAS',
  ESTOQUE_ENTRADA_MENUS_ROTAS: 'ESTOQUE_ENTRADA_MENUS_ROTAS',
  ESTOQUE_TRANSFERENCIA_MENUS_ROTAS: 'ESTOQUE_TRANSFERENCIA_MENUS_ROTAS',
  ESTOQUE_AJUSTE_MENUS_ROTAS: 'ESTOQUE_AJUSTE_MENUS_ROTAS',

  ESTOQUE_SESI_VACINAS_DR: 'ESTOQUE_SESI_VACINAS_DR',
  ESTOQUE_SESI_VACINAS_UO: 'ESTOQUE_SESI_VACINAS_UO',
  USUARIO_EMPRESA_MENUS_ROTAS: 'USUARIO_EMPRESA_MENUS_ROTAS',

  ADESAO_TERMO_DR: 'ADESAO_TERMO_DR',
  CAMPANHA_TERMO_DR: 'CAMPANHA_TERMO_DR',

  USUARIO_EMPRESA_LISTA_EMPRESA: 'USUARIO_EMPRESA_LISTA_EMPRESA',
  AGENDAMENTO_MENUS_ROTAS: 'AGENDAMENTO_MENUS_ROTAS',
  AGENDAMENTO_SESI: 'AGENDAMENTO_SESI',
  AGENDAMENTO_SESI_UO: 'AGENDAMENTO_SESI_UO',
  AGENDAMENTO_AE: 'AGENDAMENTO_AE',

  RELATORIO_SMD_MENU_ROTAS_VISUALIZAR: 'RELATORIO_SMD_MENU_ROTAS',
  SUPORTE_GERENCIAMENTO_FILA: 'SUPORTE_GERENCIAMENTO-FILA',
  SUPORTE_LIMPEZA_CACHE: 'SUPORTE_LIMPEZA-CACHE',
  SUPORTE_GERENCIAMENTO_USUARIOS_INTEGRACOES: 'SUPORTE_GERENCIAMENTO-USUARIOS-INTEGRACOES',
  ATUACAO_BASE_NACIONAL: 'ATUACAO_BASE_NACIONAL',
  TIPO_ATUACAO_GRUPO_EMPRESA: 'TIPO_ATUACAO_GRUPO_EMPRESA',
  TIPO_ATUACAO_EMPRESA_BASE_NACIONAL: 'TIPO_ATUACAO_EMPRESA_BASE_NACIONAL',

  USUARIO_EMPRESA_DN: 'USUARIO_EMPRESA_DN',
  USUARIO_EMPRESA_DR: 'USUARIO_EMPRESA_DR',
  TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL: 'TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL',
  TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR: 'TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR',
  TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_OPERADOR: 'TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_OPERADOR',
  TIPO_ATUACAO_ADESAO_BASE_NACIONAL: 'TIPO_ATUACAO_ADESAO_BASE_NACIONAL',
  TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR: 'TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR',
  TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR: 'TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR',
  SUPORTE_GERENCIAMENTO_PESSOA_FISICA: 'SUPORTE_GERENCIAMENTO-PESSOA-FISICA',

  RELATORIO_EXPORTACAO_MENU_ROTAS_VISUALIZAR: 'RELATORIO_EXPORTACAO_MENU_ROTAS',
  RELATORIO_EXPORTACAO_COLABORADORES_VACINADOS: 'RELATORIO_EXPORTACAO_COLABORADORES_VACINADOS',
  RELATORIO_EXPORTACAO_QUANTITATIVO_VACINACAO: 'RELATORIO_EXPORTACAO_QUANTITATIVO_VACINACAO',
  RELATORIO_EXPORTACAO_EMPRESAS_CADASTRADAS: 'RELATORIO_EXPORTACAO_EMPRESAS_CADASTRADAS',
  RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL: 'RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL',
  RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_BASE_NACIONAL: 'RELATORIO_EXPORTACAO_ADESAO_POR_EMPRESAS_BASE_NACIONAL',
  RELATORIO_EXPORTACAO_AGENDAMENTOS_DN: 'RELATORIO_EXPORTACAO_AGENDAMENTOS_DN',
  RELATORIO_EXPORTACAO_AGENDAMENTOS: 'RELATORIO_EXPORTACAO_AGENDAMENTOS',
  RELATORIO_EXPORTACAO_COLABORADORES_CADASTRADOS: 'RELATORIO_EXPORTACAO_COLABORADORES_CADASTRADOS',
  CIDADE_UO: 'CIDADE_UO',
  CIDADE_DR: 'CIDADE_DR',
  CIDADE_REGIAO: 'CIDADE_REGIAO',
  RELATORIO_EXPORTACAO: 'RELATORIO_EXPORTACAO',

  TIPO_ATUACAO_BASE_NACIONAL_COORDENADOR: 'TIPO_ATUACAO_BASE_NACIONAL_COORDENADOR',
  TIPO_ATUACAO_BASE_NACIONAL_OPERADOR: 'TIPO_ATUACAO_BASE_NACIONAL_OPERADOR',

  UNIDADE_PROPRIA: 'UNIDADE_PROPRIA',
  UNIDADE_TERCEIRA: 'UNIDADE_TERCEIRA',
  UNIDADE_PROPRIA_DADOS_CRIACAO: 'UNIDADE_PROPRIA_DADOS_CRIACAO',
  UNIDADE_TERCEIRA_DADOS_CRIACAO: 'UNIDADE_TERCEIRA_DADOS_CRIACAO',
  
  IMPORTACAO_PLANILHA_VACINADOS: 'IMPORTACAO_PLANILHA_VACINADOS',
}

export const actions = {
  VISUALIZAR: 'VISUALIZAR',
  INSERIR: 'INSERIR',
  INSERIR_MULTIPLO: 'INSERIR_MULTIPLO',
  ATUALIZAR: 'ATUALIZAR',
  EXCLUIR: 'EXCLUIR',
  EXCLUIR_MULTIPLO: 'EXCLUIR_MULTIPLO',
  IMPORTAR: 'IMPORTAR',
  ATUALIZAR_STATUS: 'ATUALIZAR_STATUS',
  EXPORTAR: 'EXPORTAR',
  GERENCIAR: 'GERENCIAR',
  RESPONSAVEL_ATUALIZAR: 'RESPONSAVEL_ATUALIZAR',
}
